import { motion } from "framer-motion";

import NavBar from "./NavBar";
import HeroSection from "./HeroSection";
import ServicesSection from "./ServicesSection";
import TechSection from "./TechSection";
import FollowSection from "./FollowSection";
import FooterSection from "./FooterSection";

function App() {
  return (
    <div className="min-h-screen bg-black/90">
      <NavBar />
      <HeroSection />
      <motion.h1
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="text-white text-4xl font-bold text-center mb-12 pt-10"
      >
        What We Offer
      </motion.h1>
      <ServicesSection />
      <motion.h1
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="text-white text-4xl font-bold text-center mb-12 mt-12"
      >
        How We Work
      </motion.h1>
      <FollowSection />
      <motion.h1
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="text-white text-4xl font-bold text-center mb-12 mt-12"
      >
        Technologies We Use
      </motion.h1>
      <TechSection />
      <FooterSection />
    </div>
  );
}

export default App;
